<template>
  <div v-if="isSearchPage" class="h-8 flex-1">
    <div class="relative w-full lg:mx-0 lg:w-auto">
      <div>
        <form
          class="searchbox-desktop flex"
          :class="isSearchBoxOpen ? 'searchbox-open' : null"
        >
          <div class="flex w-full">
            <input
              v-model="query"
              type="search"
              :placeholder="t('searchRestaurantPlaceHolder')"
              name="search"
              class="searchbox-input truncate pb-2"
              style="border-radius: 0; border-bottom: 1px solid #ababab"
              required
            />
            <input type="submit" class="searchbox-submit rounded-full" />
            <IconSearch
              class="searchbox-icon inline h-[30px] w-[30px] cursor-pointer rounded-full bg-red-500 p-1 font-bold text-white"
              @click.prevent="isSearchBoxOpen = !isSearchBoxOpen"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { ref, useRoute } from "#imports";
import IconSearch from "~icons/hh-icons/icon-search";
const { t } = useI18n({
  useScope: "global",
});
const route = useRoute();
const isSearchPage = ref(true);
const isSearchBoxOpen = ref(false);
const query = ref("");
</script>

<script lang="ts">
export default {
  name: "SearchDesktop",
};
</script>

<style lang="scss" scoped>
.searchbox-desktop {
  top: 0;
  right: 0;
  // min-width: 30px;
  width: 0;
  height: 30px;
  margin-left: auto;
  overflow: hidden;
  transition: width 0.3s;

  &.with-fav-button {
    width: 66px;
  }
}
.searchbox-open {
  width: 100% !important;

  @screen xl {
    width: 400px !important;
  }
}

.searchbox-input {
  top: 0;
  right: 0;
  border: 0;
  outline: 0;
  width: 100%;
  height: 30px;
  margin: 0;
  padding: 0 40px 0 0;
  border-radius: 25px;
}

.searchbox-input::-webkit-input-placeholder {
  @apply text-gray-500;
}

.searchbox-input:-moz-placeholder {
  @apply text-gray-500;
}

.searchbox-input::-moz-placeholder {
  @apply text-gray-500;
}

.searchbox-input:-ms-input-placeholder {
  @apply text-gray-500;
}

.searchbox-icon,
.searchbox-submit {
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  top: 0;

  /* font-size: 22px; */
  right: 0;
  margin: 0;
  border: 0;
  outline: 0;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
</style>
